<template>
  <div>
    <EditSingleEventForm
      :initial-form-data="singleEvent"
      :on-form-saved="onSingleEventSaved"
    />
    <b-button
      variant="outline-danger"
      :disabled="isSubmitting"
      @click="deleteSingleEvent"
    >
      Delete
    </b-button>
  </div>
</template>

<script>
import EditSingleEventForm from '@/views/pages/locations/EditSingleEventForm.vue'
import store from '@/store'
import { BButton, BModal } from 'bootstrap-vue'
import AccountService from '@/services/AccountService'

export default {
  components: {
    EditSingleEventForm,
    BButton,
    BModal,
  },
  props: [
    'locationId',
    'singleEventId',
  ],
  data() {
    return {
      isSubmitting: false,
      singleEvent: store.getters['auth/singleEventById'](this.locationId, this.singleEventId),
    }
  },
  created() {
  },
  methods: {
    async getNumberOfServiceVisitors() {
      return AccountService.getSingleServiceVisitorsCount(this.singleEventId).then(res => res.data.data)
    },
    onSingleEventSaved(apiResponse) {
      const self = this
      store.dispatch('auth/getAuthUser')
        .then(() => {
          // after saving a new location, redirect to its edit for where all the other options like services are
          self.$router.push({
            name: 'edit-location',
            params: { id: apiResponse.data.location_id },
          })
        })
    },
    async showConfirmDeleteModal() {
      const numberOfVisitors = await this.getNumberOfServiceVisitors()
      let message = 'Are you sure you want to delete this recurring service?'
      if (numberOfVisitors && numberOfVisitors.length > 0) {
        message = `Are you sure you want to delete? There are ${numberOfVisitors.length} visitors scheduled for this service, their data will be preserved in contacts`
      }
      return this.$bvModal.msgBoxConfirm(message, {
        title: 'Please Confirm',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
    },
    async deleteSingleEvent() {
      this.showConfirmDeleteModal()
        .then(async value => {
          if (value) {
            this.isSubmitting = true
            try {
              const self = this
              await AccountService.deleteSingleEvent(this.singleEventId)
              store.dispatch('auth/getAuthUser').then(() => {
                self.$router.push({
                  name: 'edit-location',
                  params: { id: self.locationId },
                })
              })
            } catch (error) {
              console.error(error)
            } finally {
              this.isSubmitting = false
            }
          }
        })
    },
  },
}
</script>
